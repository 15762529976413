.product-form {
    padding: 20px;
    margin: 90px auto;
    width: 50%;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.product-form h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

table {
    width: 100%;
    border-collapse: collapse;
}

td {
    padding: 10px;
    vertical-align: top;
}

.input-container {
    position: relative;
    width: 100%;
}



.input-container input,
.input-container select {
    width: 100%;
    padding: 12px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}



.input-container input:focus,
.input-container select:focus {
    border-color: #a5ce3b;
    outline: none;
}

.input-container label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #aaa;
    font-size: 16px;
    pointer-events: none;
    transition: all 0.3s ease;
}

.input-container input + label,
.input-container select + label {
    top: -10px;
    font-size: 12px;
    color: #a5ce3b;
    padding: 0 4px;
}

.input-container input[type="file"] + label {
    top: -10px;
    font-size: 12px;
    color: #a5ce3b;
    padding: 0 4px;
}

.submit-button {
    width: 100%;
    padding: 12px;
    background-color: #a5ce3b;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
}

.submit-button:hover {
    background-color: #7fa02d;;
}

.image-preview > img {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .product-form {
        padding: 15px;
        margin: 10px;
        margin-top: 80px;
        width: 90%; /* Adjust width for smaller screens */
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #f9f9f9;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .product-form h2 {
        font-size: 20px; /* Smaller font size for headers */
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    td {
        padding: 8px; /* Reduce padding for compactness */
        display: block; /* Stack table cells vertically */
        width: 100%;
    }

    .input-container {
        margin-bottom: 15px; /* Add spacing between input containers */
    }

    .input-container input,
    .input-container select {
        font-size: 14px; /* Slightly smaller font size */
        padding: 10px 6px; /* Reduce padding for compact fields */
    }

    .input-container label {
        font-size: 14px; /* Match input font size */
    }

    .submit-button {
        font-size: 14px; /* Adjust button text size */
        padding: 10px; /* Reduce padding for smaller buttons */
    }

    /* Ensure multi-select dropdown fits within the view */
    .input-container select[multiple] {
        min-height: 60px; /* Adjust height for smaller screens */
        font-size: 14px;
    }

}
