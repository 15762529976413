.galerie {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.galerie > div:first-child {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
}

.galerie > div:first-child > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    background-color: rgba(163, 205, 55, 0.3);
    font-size: 70px;
    font-family: title;
    letter-spacing: 3mm;

}

.galerie > div:first-child > div:first-child > p {
    text-align: center;
    width: 80%;
}

.galerie > div:first-child > div:nth-child(2) {
    width: 35%;
    background-image: url("../../../public/images/projects/interior.jpeg");
    background-size: cover;
}

.galerie > div:first-child > div:nth-child(2) > div {
    width: 100%;
    height: 100%;
    z-index: 999;
}

@media (max-width: 600px) {

    .galerie > div:first-child {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .galerie > div:first-child > div:first-child {
        width: 100%;
        font-size: 40px;
    }

    .galerie > div:first-child > div:nth-child(2) {
        width: 100%;
        background-size: cover;
    }

}
