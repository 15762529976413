.gallery-image {
    display: flex;
    width: 430px;
    height: 300px;
    margin-top: 20px;
    cursor: pointer;
}

.gallery-image > img {
    width: 100%;
}

.gallery-image img:hover {
    transform: scale(1.05);
    transition-duration: 0.2s;
}

/* Overlay pour l'image agrandie */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Contenu du modal */
.modal-content {
    max-width: 90%;
    max-height: 90%;
    background: transparent;
    padding: 20px;
    border-radius: 10px;
}

.modal-content img {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

@media (max-width: 600px) {
    .gallery-image {
        width: 350px;
        height: 200px;
        margin-top: 20px;
    }

    .modal-content img {
        max-width: 95vw;
        max-height: 75vh;
    }
}
