.contactUs {
    width: 100%;
    min-height: 110vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.contactUs>div:nth-child(1) {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    margin-left: 20px;
    overflow: auto;
}

.contactUs>div:nth-child(1)>span {
    width: 90%;
    color: #a5ce3b;
    font-size: 22px;
    font-weight: bold;
}

.contactUs>div:nth-child(1)>header {
    width: 90%;
    text-align: left;
    margin: 10px 0;
    font-size: 50px;
    letter-spacing: 0.1em;
}

.contactUs>div:nth-child(1)>p {
    margin-top: 5px;
    width: 90%;
    font-size: 15px;
    letter-spacing: 0.09em;
}

.contactUs>div:nth-child(1)>div {
    margin-top: 18px;
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.contactUs>div:nth-child(1)>div>input,
.contactUs>div:nth-child(1)>div>textarea {
    border: none;
    padding: 15px 10px;
    font-size: 14px;
    border-radius: 6px;
    background-color: whitesmoke;
    margin: 15px 0;
    outline: none;
    font-family: "kiro", sans-serif;
}

.contactUs>div:nth-child(1)>div>input:nth-child(1),
.contactUs>div:nth-child(1)>div>input:nth-child(2) {
    width: 45%;
}

.contactUs>div:nth-child(1)>div>input:nth-child(3),
.contactUs>div:nth-child(1)>div>input:nth-child(4),
.contactUs>div:nth-child(1)>div>textarea {
    width: 100%;
    resize: none;
}

.contactUs>div:nth-child(1)>div>span {
    width: fit-content;
    padding: 7px 20px;
    background-color: rgba(165, 206, 59, 0.9);
    color: white;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.contactUs>div:nth-child(1)>div>span:hover {
    background-color: rgba(165, 206, 59, 1);;
}

.contactUs>div:nth-child(2) {
    width: 35%;
    min-height: 100%;
    background-image: url("../../../public/images/projects/office.jpeg");
    background-size: cover;
}

@media (max-width: 1185px) {
    .contactUs>div:nth-child(1) {
        width: 50%;
    }

    .contactUs>div:nth-child(2) {
        width: 45%;
    }
}

@media (max-width: 950px) {
    .contactUs {
        min-height: fit-content;
        margin-bottom: 50px;
    }

    .contactUs>div:nth-child(1) {
        width: 90%;
        margin: 10px auto;
    }

    .contactUs>div:nth-child(2) {
        display: none;
    }

    .contactUs>div:nth-child(1)>span {
        width: 100%;
        font-size: 21px;
    }

    .contactUs>div:nth-child(1)>header {
        width: 100%;
        font-size: 37px;
        letter-spacing: 0.1em;
    }

    .contactUs>div:nth-child(1)>p {
        width: 100%;
        font-size: 15px;
    }

    .contactUs>div:nth-child(1)>div {
        width: 100%;
    }

    .contactUs>div:nth-child(1)>div>input,
    .contactUs>div:nth-child(1)>div>textarea {
        padding: 13px 7px;
        font-size: 13px;
        margin: 10px 0;
    }

    .contactUs>div:nth-child(1)>div>input:nth-child(1),
    .contactUs>div:nth-child(1)>div>input:nth-child(2) {
        width: 42%;
    }

    .contactUs>div:nth-child(1)>div>span {
        width: 100%;
        padding: 7px;
        font-size: 17px;
        text-align: center !important;
    }
}