.side-navbar{
    display: flex;
    flex-direction: column;
    width: 20%;
    min-height:100vh ;
    background-color: black;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: translateX(-100%);
    transition: transform 0.6s ease-out, opacity 0.6s ease-out;
    opacity: 0;
    border-top-right-radius: 15px;
    color: white;
    overflow: hidden;
}

.side-navbar.slide-in {
    transform: translateX(0);
    opacity: 1;
}

.side-navbar > header {
    font-size: 15px;
    font-family: luxe;
    text-transform: uppercase;
    padding-top: 10px;
    margin: 0 auto;
    width: 80%;
    padding-bottom: 10px;
}

.side-navbar > div {
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 100%;
    
}

.side-navbar > div:nth-child(odd) {
    background-color: rgb(211, 211, 211, 0.1);
}


.side-navbar > div > div > header{
    font-size: 15px;
    letter-spacing: 0.05em;
    font-family: title;
    transition-duration: 0.1s;
    cursor: pointer;
    text-transform: uppercase;

}

@keyframes vibration {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}

.vibrate {
    animation: vibration 0.3s ease infinite;
}


.side-navbar > div > div:nth-child(1){
    display: flex;
    flex-direction: row;
}


.side-navbar > div > div> header:hover{
    letter-spacing: 0.07em;
    color: #a5ce3b;
    
}
.side-navbar > div > div:nth-child(1) > img {
    rotate: 90deg;
    width: 15px;
    margin-right: 5px;
    align-self: center;
    transition: transform 0.3s ease; /* Smooth rotation */
}

.side-navbar > div > div:nth-child(1) > img.rotated {
    transform: rotate(90deg);
}

.side-navbar > div > div:nth-child(2) {
    font-size: 15px;
    letter-spacing: 0.05em;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    transition-duration: 0.2s;

}

.side-navbar > div > div:nth-child(2) > span {
    cursor: pointer;
    margin-top: 7px;
    text-transform: capitalize;
}

.arrow{
    display: none;
}

@media (max-width: 786px) {

    .arrow{
        display: flex;
        position: fixed;
        height: 40px;
        top: 100px;
        transition: all 0.2s ease;
    }
    .side-navbar{
        width: 50%;
        margin-top: 60px;
        position: fixed;
        margin-left: 0;
        left: 0;
        top: 0;
        transition: transform 0.2s ease;
    }    
    .side-navbar.hidden {
        transform: translateX(-100%);
    }
    
    .side-navbar.visible {
        transform: translateX(0);
    }
    .arrow.rotated-arrow {
        transform: translateX(206px) rotate(180deg);
        
    }
    
}

