.back-office-header{
    display: flex;
    flex-direction: row;
    height: 80px;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: 0.1em;
    position: fixed;
    background-color: black;
    padding: 7px 0;
    justify-content: space-between;
    top: 0;
    width: 100%;
    transition: background-color  0.5s ease;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 99;
}
.back-office-header > a >  img{
    display: flex;
    align-items: center;
    height: 95%;
    margin: 0 40px ;
}
.back-office-header > div{
    display: flex;
    align-items: center;
}
.back-office-header > div > a{
    text-decoration: none;
    width: 180px;
    align-content: center;
    padding: 0 10px;
    margin-left: 20px;
    border-bottom: 2px double transparent ;
    cursor: pointer;
    color: white;
    transition:color 0.5s ease, border-bottom 0.5s ease;
    

}
.back-office-header > div > a:hover {
    color: #a5ce3b !important;
}

.back-office-header > img:nth-child(3){
    visibility: hidden;
}

.hidden-navbar{
    display: none;
}

.shadow{
    display: none;
}



@media (max-width: 760px) {
    .back-office-header {
        height: 50px;
    }

    .back-office-header > div{
        display: none;
    }

    .back-office-header > img:nth-child(3){
        visibility: visible;
        width: 50px;
        height: 50px;
    }

    .hidden-navbar{
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.8);
        height: 100vh;
        color: whitesmoke;
        width: 50%;
        position: fixed;
        z-index: 999;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        transition: transform 0.2s ease;
        left:450px;
    }

    .hidden-navbar > img{
        width: 30%;
        margin: 20px 35px;
    }
    .hidden-navbar > a{
        margin-top: 40px;
        text-decoration: none;
        height: 30px;
        align-content: center;
        padding: 0 10px;
        margin-left: 20px;
        font-size: 20px;
        border-bottom: 2px double transparent ;
        cursor: pointer;
        color: whitesmoke;
    }

    .hidden-navbar.visible {
        
        transform: translateX(-250px); 
      }

      .menu-icon {
        transition: transform 0.3s ease; /* Smooth rotation */
      }
      
      .menu-icon.rotate {
        transform: rotate(90deg);
      }
      
      .shadow-visible{
        display: flex;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        position: fixed;
      }
}