.back-office-side-navbar {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: black;
    color: white;
    text-transform: capitalize;
}

.back-office-side-navbar > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 30px;
}

.back-office-side-navbar > div:nth-child(1) > img {
    border-radius: 100px;
    height: 150px;
    width: 150px;
    margin-top: 80px;
}

.back-office-side-navbar > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    font-size: 20px;
}

.back-office-side-navbar > div:nth-child(2) > span {
    margin-top: 15px;
    cursor: pointer;
}
.back-office-side-navbar > div:nth-child(2) > a {
    color: white;
    text-decoration: none;
    margin-top: 15px;
}

.logout-button {
    border: none;
    color: black;
    height: 40px;
    width: 70%;
    font-family: luxe;
    font-size: 25px;
    text-align: center;
    align-content: center;
    background-color: #a5ce3b;
    border-radius: 15px;
    transition-duration: 0.3s;
    margin-top: 50px;
    cursor: pointer;
}

.logout-button:hover {
    background-color:#893f1e;
    color: whitesmoke;
}
.arrow{
    display: none;
}


@media only screen and (max-width: 768px) {

    .back-office-side-navbar{
        width: 90%;
        margin-top: 60px;
        position: fixed;
        margin-left: 0;
        left: 0;
        top: 0;
    }

    .back-office-side-navbar > div:nth-child(1) > img {
        display : none
    }

    .arrow{
        display: flex;
        height: 40px;
        margin-top: 15px;
        background-color: transparent;
        transition: all 0.2s ease;
    }

    .logout-button {
        height: 40px;
        width: 70%;
        font-family: luxe;
        font-size: 15px;
        text-align: center;
        align-content: center;
        background-color: #a5ce3b;
        border-radius: 15px;
        transition-duration: 0.3s;
        margin-top: 50px;
        cursor: pointer;
    }

    .back-office-side-navbar.hidden {
        transform: translateX(-100%);
    }
    
    .back-office-side-navbar.visible {
        transform: translateX(0);
    }
    .arrow.rotated-arrow {
        transform: translateX(220px) rotate(180deg);
        
    }

    
        

}