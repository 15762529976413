.decor {
    display: flex;
    flex-direction: column;
    width: 80%;
} 

.decor > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 60px;
    background-color: whitesmoke;
    transition-duration: 0.3s;
    max-height:250px;
    overflow: hidden;
    align-items: flex-end;
}

.decor > div:nth-child(2) > img {
    width: 40%;
    height: 100%;
}

.decor > div:nth-child(2) > div {
    margin: auto;
    font-size: 30px;
    color: black;
}

.decor > div:nth-child(2)  > div > header{
    font-family: title;
}

.decor > div:nth-child(1) {
    position: relative;
    width: 100%;
    height: 15px; 
}

.decor > div:nth-child(1) img {
    position: absolute;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.decor > div:nth-child(1) img:first-child {
    top: -15px; 
    left: 0; 
}

.decor > div:nth-child(1) img:last-child {
    top: -15px;
    right: 0;
}

@media (max-width: 1000px){
    .decor > div:nth-child(1) > img:nth-child(2) {
        left: 83%;
    }

    .decor > div:nth-child(2)  {
        width: 90%;
        margin-bottom: 20px;
    }

    .decor > div:nth-child(2) > div{
        font-size: 18px;
    }

    .decor > div:nth-child(2) > div:nth-child(2){
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33%;
    }
    .decor > div:nth-child(2) > div:nth-child(2) > header{
        text-align: center;
    }
    .decor > div:nth-child(2) > img {
        height: 100px;
    }
    .decor > div:nth-child(2) > img:nth-child(1) {
        width: 33%;
    }
    .decor > div:nth-child(2) > img:nth-child(3) {
        width: 33%;
    }
}