.user-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
    width: 350px;
    margin: 20px;
    /*background-color:#893f1e;
    background-color: #a5ce3b;*/
    border-radius: 20px;
    background-color: whitesmoke;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.user-card > div {
    margin: 5px;
}

.user-card > div:nth-child(1) > img {
    width: 100%;
    border-radius: 100px;
}

.user-card > div:nth-child(1) {
    justify-content: center;
    border-radius: 100px;
    width: 50%;
}

.user-card > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    height: 21%;
    justify-content: space-between;
}
.user-card > div:nth-child(3) {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    cursor: pointer;
    margin-top: 40px;
}

.user-card > div:nth-child(3) > span {
    font-size: 22px;
    padding: 10px 20px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}
.user-card > div:nth-child(3) > span:nth-child(1){
    background-color: rgba(240, 240, 67, 0.9);
    color: black;
}

.user-card > div:nth-child(3) > span:nth-child(2){
    background-color: rgba(224, 69, 69, 0.9);
}

