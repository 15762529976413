.user-form {
    padding: 20px;
    margin: 90px auto;
    width: 50%;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.user-form h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

table {
    width: 100%;
    border-collapse: collapse;
}

td {
    padding: 10px;
    vertical-align: top;
}

.input-container {
    position: relative;
    width: 100%;
}

.input-container select {
    width: 100%;
    padding: 12px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.input-container input:focus,
.input-container select:focus {
    border-color: #a5ce3b;
    outline: none;
}

.input-container label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #aaa;
    font-size: 16px;
    pointer-events: none;
    transition: all 0.3s ease;
}

.input-container input:focus + label,
.input-container select:focus + label {
    top: -10px;
    font-size: 12px;
    color: #a5ce3b;
    padding: 0 4px;
}

.submit-button {
    width: 100%;
    padding: 12px;
    background-color: #a5ce3b;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
}

.submit-button:hover {
    background-color: #7fa02d;;
}

.checkbox-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.checkbox-label {
    cursor: pointer; /* Makes the label clickable */
}

.input-container input[type="checkbox"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.non-admin { 
    height: 70ch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.non-admin > p{
    margin: auto;
    font-size: 22px;
}