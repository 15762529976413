.header{
    display: flex;
    flex-direction: row;
    height: 80px;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 7px 0;
    justify-content: space-between;
    top: 0;
    width: 100%;
    transition: background-color  0.5s ease;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 99;
}
.header > img{
    display: flex;
    align-items: center;
    height: 95%;
    margin: 0 40px ;
}
.header > div{
    display: flex;
    align-items: center;
}
.header > div > a{
    text-decoration: none;
    height: 30px;
    align-content: center;
    padding: 0 10px;
    margin-left: 20px;
    border-bottom: 2px double transparent ;
    cursor: pointer;
    color: white;
    transition:color 0.5s ease, border-bottom 0.5s ease;
    

}
.header > div > a:hover {
    color: #a5ce3b !important;
    border-bottom: 2px double #893f1e;
}

.header > img:nth-child(3){
    visibility: hidden;
}

.hidden-navbar{
    display: none;
}

.shadow{
    display: none;
}

.hidden-details {
    display: flex;
    flex-direction: row;
    background-color: whitesmoke;
    width: 40%;
    height: 150px;
    justify-content: space-around;
    position: fixed;
    border-radius: 15px;
    padding-top: 5px;
    margin-top: 90px;
    left: 30%;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;
    z-index: 999;
    
}

.hidden-details.visible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}
.hidden-details:hover{
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

.hidden-details > div > div  {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: title;
    font-size: 18px;
}

.hidden-details > div > div:nth-child(2){
    display: flex;
    flex-direction: column;
    align-items: normal;
}

.hidden-details > div > div:nth-child(1) > header{
    font-family: title;
    letter-spacing: 0.05em;

}
.hidden-details > div > div:nth-child(2) > li{
    margin-top: 5px;
    margin-left: 12px;
}
.hidden-details > div > div > img {
    height: 30px;
    margin-right: 10px;
}

.hidden-details > div > div:nth-child(2) > a{
    text-decoration: none ;
    color: black ;
    border-bottom:1px solid whitesmoke;
    transition-duration: 0.3s;
}

.hidden-details > div > div:nth-child(2) > a :hover{
    color: #a5ce3b;
    letter-spacing: 0.05em;
    transition-duration: 0.3s;
}


@media (max-width: 760px) {
    .header {
        height: 50px;
    }

    .header > div{
        display: none;
    }

    .header > img:nth-child(3){
        visibility: visible;
        width: 50px;
        height: 50px;
    }

    .hidden-navbar{
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.8);
        height: 100vh;
        color: whitesmoke;
        width: 50%;
        position: fixed;
        z-index: 999;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        transition: transform 0.2s ease;
        left:450px;
    }

    .hidden-navbar > img{
        width: 30%;
        margin: 20px 35px;
    }
    .hidden-navbar > a{
        margin-top: 40px;
        text-decoration: none;
        height: 30px;
        align-content: center;
        padding: 0 10px;
        margin-left: 20px;
        font-size: 20px;
        border-bottom: 2px double transparent ;
        cursor: pointer;
        color: whitesmoke;
    }

    .hidden-navbar.visible {
        
        transform: translateX(-250px); 
      }

      .menu-icon {
        transition: transform 0.3s ease; /* Smooth rotation */
      }
      
      .menu-icon.rotate {
        transform: rotate(90deg);
      }
      
      .shadow-visible{
        display: flex;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        position: fixed;
      }
}