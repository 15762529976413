.products-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;

}

.group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}

.models-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 3%;
}


.no-models {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 50px;
}

.no-models > img {
    width: 40%;
}


.backoffice-actions {
    display: flex;
    position: relative;
    top: -100%;
    height: 50%;
    visibility: hidden; /* Initially hidden */
    opacity: 0; /* Fully transparent */
    transition: visibility 0s, opacity 0.3s ease-in-out; /* Smooth transition */
}

.backoffice-model:hover .backoffice-actions {
    visibility: visible; /* Show on hover */
    opacity: 1; /* Fully visible */
}

.backoffice-actions > img {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.backoffice-actions > img:nth-child(2) {
    margin-left: 83%;
}



@media (max-width: 1000px) {
    .products-list {
        width: 100%;
        margin: 70px auto;
    }    

    .products-list > div:first-child {
        margin-top: 0;
    }
}





