.login {
    /*#893f1e;*/
    /*background: rgb(165,206,59);*/
    /*background: linear-gradient(341deg, rgba(165,206,59,1) 0%, rgba(137,63,30,1) 0%, rgba(165,206,59,1) 100%);*/
    background-image: url('../../../public/images/home/1.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login > div {
    display: flex;
    flex-direction: row;
    width: 60%;
    height: 65vh;
    background-color: whitesmoke;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    align-items: center;
    margin-top: 50px;
}

.login > div > div {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.login > div > div:first-child {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.login > div > div:first-child > img {
    height: 70%;
}

.login > div > form:nth-child(2) {
    display: flex;
    flex-direction: column;
    height: 60%;
    width: 50%;
    justify-content: space-around;
    align-items: center;
}

.login > div > form:nth-child(2) > span:first-child {
    font-family: luxe;
    font-size: 30px;
}
.login > div > form:nth-child(2) > input {
    height: 40px;
    width: 70%;
    border-radius: 15px;
    outline: none;
    border: none;
    background-color: rgb(223, 223, 223);
    font-size: 20px;
    text-align: center;
}

.login > div > form:nth-child(2) > button {
    border: none;
    color: black;
    height: 40px;
    width: 70%;
    font-family: luxe;
    font-size: 25px;
    text-align: center;
    align-content: center;
    background-color: #a5ce3b;
    border-radius: 15px;
    transition-duration: 0.3s;
    cursor: pointer;
}

.login > div > form:nth-child(2) > button:hover {
    background-color:#893f1e;
    color: whitesmoke;
}

@media (max-width: 1000px) {
    .login > div {
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .login > div > div:first-child {
        flex-direction: column;
    }
    
    .login > div > div:first-child > img {
        height: 100%;
    }

    .login > div > form:nth-child(2) {
        width: 80%;
    }
    .login > div > form:nth-child(2) > input {
        width: 100%;
    }
    
    .login > div > form:nth-child(2) > button {
        width: 100%;
    }
}