.back-office-home {
    display: flex;
    flex-direction: row;
}
.back-office-home > div:first-child {
    width: 20%;
}

.back-office-content {
    width: 90%;
    margin-top: 7%;
}
@media only screen and (max-width: 768px) {

    .back-office-home > div:first-child {
        width: 57%;
    }
}